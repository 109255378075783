<template>
  <div class="min-h-screen bg-white">
    <div class="max-w-7xl mx-auto px-4 py-8">
      <template v-if="!isLoading">
        <!-- Featured and Recent Posts Section -->
        <section class="mb-24">
          <!-- Featured Post -->
          <div v-if="featuredPost" class="mb-16">
            <router-link :to="`/blog/${featuredPost.slug}`" class="cursor-pointer block group">
              <div v-if="featuredPost.featuredImage" class="w-full aspect-[16/9] rounded-xl overflow-hidden mb-4">
                <img
                  :src="featuredPost.featuredImage"
                  :alt="featuredPost.title"
                  class="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-500"
                />
              </div>
              <h2 class="text-3xl font-bold text-brand-black group-hover:text-brand-spot transition-colors mb-3">
                {{ featuredPost.title }}
              </h2>
              <div class="flex items-center text-sm text-light-grey">
                <span v-if="featuredPost.author">by {{ featuredPost.author.name }}</span>
                <span class="mx-2" v-if="featuredPost.author">·</span>
                <span>{{ formatDate(featuredPost.publishDate) }}</span>
              </div>
            </router-link>
          </div>

          <!-- Recent Posts Grid -->
          <div class="mt-24">
            <h2 class="text-xl font-medium mb-8">RECENT POSTS</h2>
            <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 lg:gap-12">
              <router-link v-for="post in recentPosts" :key="post.id" :to="`/blog/${post.slug}`" class="cursor-pointer flex flex-col group">
                <div v-if="post.featuredImage" class="aspect-[4/3] rounded-xl overflow-hidden mb-3">
                  <img
                    :src="post.featuredImage"
                    :alt="post.title"
                    class="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-500"
                  />
                </div>
                <h3 class="text-base sm:text-lg font-medium mb-2 leading-tight line-clamp-2 group-hover:text-brand-spot transition-colors flex-grow">
                  {{ post.title }}
                </h3>
                <div class="flex items-center text-sm text-light-grey">
                  <span v-if="post.author" class="line-clamp-1">by {{ post.author.name }}</span>
                  <span class="mx-2" v-if="post.author">·</span>
                  <span>{{ formatDate(post.publishDate) }}</span>
                </div>
              </router-link>
            </div>
          </div>
        </section>

        <!-- All Other Posts -->
        <section>
          <h2 class="text-xl font-medium mb-8">ALL POSTS</h2>
          <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 lg:gap-12">
            <router-link v-for="post in remainingPosts" :key="post.id" :to="`/blog/${post.slug}`" class="cursor-pointer flex flex-col group">
              <div v-if="post.featuredImage" class="aspect-[4/3] rounded-xl overflow-hidden mb-3">
                <img
                  :src="post.featuredImage"
                  :alt="post.title"
                  class="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-500"
                />
              </div>
              <h3 class="text-base sm:text-lg font-medium mb-2 leading-tight line-clamp-2 group-hover:text-brand-spot transition-colors flex-grow">
                {{ post.title }}
              </h3>
              <div class="flex items-center text-sm text-light-grey">
                <span v-if="post.author" class="line-clamp-1">by {{ post.author.name }}</span>
                <span class="mx-2" v-if="post.author">·</span>
                <span>{{ formatDate(post.publishDate) }}</span>
              </div>
            </router-link>
          </div>
        </section>
      </template>

      <!-- Skeleton Loader -->
      <BlogSkeleton v-else />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { getBlogPosts } from '@/services/contentful';
import BlogSkeleton from '@/components/BlogSkeleton.vue';

const blogPosts = ref([]);
const isLoading = ref(true);

// Sort posts by featured status, date and featured image
const sortedPosts = computed(() => {
  return [...blogPosts.value].sort((a, b) => {
    // First prioritize featured posts with featured images
    const aIsFeatured = a.featured && a.featuredImage;
    const bIsFeatured = b.featured && b.featuredImage;
    
    if (aIsFeatured && !bIsFeatured) return -1;
    if (!aIsFeatured && bIsFeatured) return 1;
    
    // Then sort by date
    const dateComparison = new Date(b.publishDate) - new Date(a.publishDate);
    if (dateComparison !== 0) return dateComparison;

    // If dates are equal, prioritize posts with featured images
    if (a.featuredImage && !b.featuredImage) return -1;
    if (!a.featuredImage && b.featuredImage) return 1;
    return 0;
  });
});

// Get the first featured post with a featured image
const featuredPost = computed(() => {
  return sortedPosts.value.find(post => post.isFeatured && post.featuredImage) || sortedPosts.value[0];
});

// Get the next 3 most recent posts (excluding featured)
const recentPosts = computed(() => {
  const featuredPostId = featuredPost.value?.id;
  return sortedPosts.value
    .filter(post => post.id !== featuredPostId)
    .slice(0, 3);
});

// Get all remaining posts (excluding featured and recent)
const remainingPosts = computed(() => {
  const shownPostIds = new Set([featuredPost.value?.id, ...recentPosts.value.map((post) => post.id)]);
  return sortedPosts.value.filter((post) => !shownPostIds.has(post.id));
});

// Format date helper
const formatDate = (dateString) => {
  return new Date(dateString).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
};

const fetchBlogPosts = async () => {
  try {
    const posts = await getBlogPosts();
    blogPosts.value = posts;
  } catch (error) {
    console.error('Error loading blog posts:', error);
  } finally {
    isLoading.value = false;
  }
};

onMounted(fetchBlogPosts);
</script>
